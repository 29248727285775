import {
  PlaceSortableDirections,
  PlaceSortableProperties,
  PlaceFilterType,
} from "./../models/place";
import { Injectable } from "@angular/core";

import { Observable, from } from "rxjs";
import { catchError, map, tap, take } from "rxjs/operators";

import { Place } from "../models/place";
import { PlatformHelperService } from "./platform-helper.service";
import { SharedService } from "./shared.service";
import {
  LocationGQL,
  LocationsGQL,
  LocationsQuery,
  DeleteLocationGQL,
  CreateLocationGQL,
  CreateLocationInput,
  ReportLocationGQL,
  Image,
  Comment,
} from "../graphql/generated/graphql.types";
import { Apollo } from "apollo-angular";
import { DataProxy, WatchQueryFetchPolicy } from "@apollo/client/core";

// TODO: Fix broken sort after search is cleared

export interface SortAndFilter {
  sortOnProperty: PlaceSortableProperties;
  sortOnDirection: PlaceSortableDirections;
  filterDistance: number;
  filterDistanceFrom: "user" | "search" | "off";
  filterType: PlaceFilterType;
  searchResult?: {
    lat: number;
    lng: number;
    zoom: number;
    name: string;
    id: string;
  };
}

@Injectable({ providedIn: "root" })
export class PlaceService {
  public sortAndFilter: SortAndFilter = {
    sortOnProperty: "createdAt",
    sortOnDirection: "descending",
    filterDistance: 20,
    filterDistanceFrom: "user",
    filterType: "ALL",
  };

  constructor(
    private apollo: Apollo,
    private platformHelperService: PlatformHelperService,
    private shared: SharedService,
    private locationGql: LocationGQL,
    private locationsGql: LocationsGQL,
    private deleteLocationGql: DeleteLocationGQL,
    private createLocationGql: CreateLocationGQL,
    private reportLocationGql: ReportLocationGQL
  ) {
    this.platformHelperService.addIsActiveListener("active", () => {
      this.getPlaces("network-only").pipe(take(1)).subscribe();
    });
    this.getPlaces("network-only").pipe(take(1)).subscribe();
  }

  /** GET place by id. Return `undefined` when id not found */
  public getPlace(
    id: string,
    fetchPolicy: WatchQueryFetchPolicy = "cache-and-network"
  ): Observable<Place> {
    return this.locationGql.watch({ id }, { fetchPolicy }).valueChanges.pipe(
      map((place): Place => {
        const newPlace = new Place(place.data.location);
        if (typeof newPlace !== "undefined") {
          return newPlace;
        }
        throw new Error("Improper server response on fetching place");
      }),

      catchError((error) => {
        throw this.shared.apolloErrorHandler(error, "Error deleting place");
      })
    );
  }

  // Download Places and store them
  public getPlaces(
    fetchPolicy: WatchQueryFetchPolicy = "cache-and-network"
  ): Observable<Place[]> {
    // console.log('Getting places');
    // TODO: For some reason if you add a location it is shown. But then, if you view a loation
    // and go back to th emap when this is run, the locaiton is delted. So I chanched it to cache-and-netowk
    // but something is wrong with the chace when this is run (but it's right before?!)
    return this.locationsGql
      .watch({ input: {} }, { fetchPolicy })
      .valueChanges.pipe(
        map((response) => {
          if (response.data.locations) {
            return response.data.locations.map((place) => {
              return new Place(place);
            });
          }
          throw new Error("Invalid response from server");
        }),
        catchError((error) => {
          console.log(error.message);
          return from([]);
        })
      );
  }

  deletePlace(placeId: string): Observable<boolean> {
    return this.deleteLocationGql
      .mutate(
        { id: placeId },
        {
          update: (proxy, result) => {
            if (!result.data || !result.data.deleteLocation) return;

            // Read the data from our cache for this query.
            try {
              const data: LocationsQuery | null = proxy.readQuery({
                query: this.locationsGql.document,
                variables: {
                  input: {},
                },
              });

              if (data) {
                const newData: any = { ...data };

                const newLocations = [...newData.locations];
                const index = newLocations.findIndex(
                  (edge) => edge._id === placeId
                );
                if (index > -1) {
                  newLocations.splice(index, 1);
                }
                newData.locations = newLocations;

                // Write our data back to the cache.
                proxy.writeQuery({
                  query: this.locationsGql.document,
                  data: newData,
                });
              }
            } catch (error) {
              console.log("error");
            }

            // try {
            //   const data: LocationQuery | null = proxy.readQuery({
            //     query: this.locationGql.document,
            //     variables: {
            //       id: placeId,
            //     },
            //   });

            //   if (data) {
            //     const fullProxy: any = proxy;
            //     const rootQuery = fullProxy.data.data.ROOT_QUERY;
            //     rootQuery.delete(`location({"id":"${placeId}"})`);
            //   }
            // } catch (error) {}
          },
        }
      )
      .pipe(
        map((result): boolean => {
          return true;
        }),
        tap((_) => {
          console.log(`deleted place id=${placeId}`);
        }),
        catchError((error) => {
          throw this.shared.apolloErrorHandler(error, "Error deleting place");
        })
      );
  }

  updateCache(
    placeId: string,
    data: {
      addImages?: Image[];
      addComments?: Comment[];
      removeImages?: Image[];
      removeComments?: Comment[];
    },
    proxy?: DataProxy
  ): Observable<Place> {
    return this.getPlace(placeId).pipe(
      take(1),
      tap((oldPlace) => {
        const place = { ...oldPlace };
        if (data.addImages) place.images = data.addImages.concat(place.images);
        if (data.addComments)
          place.comments = data.addComments.concat(place.comments);
        if (data.removeImages) {
          data.removeImages.forEach((removeImage) => {
            place.images = place.images.filter(
              (image) => removeImage._id !== image._id
            );
          });
        }
        if (data.removeComments) {
          data.removeComments.forEach((removeComment) => {
            place.comments = place.comments.filter(
              (comment) => removeComment._id !== comment._id
            );
          });
        }
        if (data.addComments || data.removeComments) {
          let sum = 0;
          place.comments.forEach((comment) => (sum += comment.rating));
          if (place.comments.length > 0) {
            place.averageRating = sum / place.comments.length;
          } else place.averageRating = 0;
          place.commentCount = place.comments.length;
        }
        const client = proxy ? proxy : this.apollo.client;
        client.writeQuery({
          query: this.locationGql.document,
          data: { location: place },
        });
      })
    );
  }

  addPlace(input: CreateLocationInput): Observable<Place> {
    return this.createLocationGql
      .mutate(
        { input: input },
        {
          update: (proxy, result) => {
            if (!result.data) return;

            const location = result.data.createLocation;
            // Read the data from our cache for this query.
            const cache: LocationsQuery | null = proxy.readQuery({
              query: this.locationsGql.document,
              variables: {
                input: {},
              },
            });

            if (!cache) return;

            const newCacheLocations = [...cache.locations];
            newCacheLocations.push(location);
            const newCache = { ...cache };
            newCache.locations = newCacheLocations;

            // Write our data back to the cache.
            proxy.writeQuery({
              query: this.locationsGql.document,
              data: newCache,
            });
          },
        }
      )
      .pipe(
        // return this.http.post<Place>(this.placesUrl, place, httpOptions).pipe(
        map((result) => {
          if (!result.data) throw new Error("Error adding place");
          const resultPlace = new Place(result.data.createLocation);
          return resultPlace;
        }),
        tap((newPlace: Place) =>
          console.log(`added place w/ id=${newPlace._id}`)
        ),
        catchError((error) => {
          throw this.shared.apolloErrorHandler(error, "Error adding place");
        })
      );
  }

  public reportPlace(id: string, issue: string): Observable<boolean> {
    return this.reportLocationGql.mutate({ id, issue }).pipe(
      // return this.http.post(this.reportPlaceUrl, data, httpOptions).pipe(
      map((response: any): boolean => true),
      catchError((error) => {
        throw this.shared.apolloErrorHandler(error, "Error reporting place");
      })
    );
  }
}
