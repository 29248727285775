import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { GetUserLocationComponent } from '../get-user-location/get-user-location.component';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  imports: [CommonModule, IonicModule, ReactiveFormsModule],
  declarations: [GetUserLocationComponent],
  exports: [IonicModule, GetUserLocationComponent],
})
export class GetUserLocationModule {}
