import { Injectable } from "@angular/core";
import { Platform } from "@ionic/angular";
import { Image } from "../graphql/generated/graphql.types";
import { Camera, CameraResultType, CameraSource, Photo } from "@capacitor/camera";
import { Geolocation } from "@capacitor/geolocation";
import { Network } from "@capacitor/network";
import { App, AppState } from "@capacitor/app";
import { Observable, from, of } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { Diagnostic } from "@ionic-native/diagnostic/ngx";

// const { Camera, Geolocation, Network, App } = Plugins;
function hasKey<K extends string, T extends object>(k: K, o: T): o is T & Record<K, unknown> {
  return k in o;
}

function isError(error: unknown): error is { message: string; code?: number } {
  return typeof error === "object" && error !== null;
}

export class PositionError extends Error {
  code: number;

  constructor(message: string, code: number) {
    super(message);
    this.code = code;
  }
}

@Injectable({
  providedIn: "root",
})
export class PlatformHelperService {
  public images: Image[] = [];
  public photoTaken = false;

  constructor(private platform: Platform, private diagnostic: Diagnostic) {
    this.choosePhoto = this.choosePhoto.bind(this);
    this.takePhoto = this.takePhoto.bind(this);
  }

  navigateToLocation(lat: number, lng: number, label = "") {
    if (this.platform.is("ios")) {
      window.open(`maps://?q=${lat},${lng}`, `_system`);
    } else if (this.platform.is("android")) {
      window.open(`geo:0,0?q=${lat},${lng}(${label})`, `_system`);
    } else {
      window.open(`https://www.google.com/maps/search/?api=1&query=${lat},${lng}`);
    }
  }

  public triggerAskForLocationEnable(): Promise<void> {
    if (this.isNative() && Geolocation) {
      return Geolocation.getCurrentPosition({
        enableHighAccuracy: false,
        timeout: 15000,
      }).then(
        (resolve) => Promise.resolve(),
        (reject) => Promise.resolve()
      );
      // return this.diagnostic.requestLocationAuthorization().then(
      //   worked => {
      //     return true;
      //   },
      //   rejected => {
      //     console.log('rejected');
      //     return false;
      //   }
      // );
    } else {
      return Promise.resolve();
    }
  }

  public isNative(): boolean {
    if (this.platform.is("hybrid")) {
      return true;
    }
    return false;
  }

  public isOnline(): Observable<boolean> {
    if (Network) {
      return from(Network.getStatus()).pipe(
        map((status) => {
          if (status.connected) {
            return true;
          } else {
            return false;
          }
        }),
        catchError((error) => {
          // NOTE: navigator.onLine temporarily required until Network Capacitor
          // plugin has web implementation
          if (navigator.onLine) {
            return of(true);
          } else {
            return of(false);
          }
        })
      );
    }
    throw new Error("Could not access network");
  }

  public async isLocationServiceAvailable(): Promise<boolean> {
    try {
      if (this.isNative()) {
        const locationServicesAvailable = await this.diagnostic.isLocationEnabled();
        if (locationServicesAvailable) {
          const locationServicesAuthorized = await this.diagnostic.isLocationAuthorized();
          if (locationServicesAuthorized) {
            console.debug(`Location is available and authorized: ${locationServicesAuthorized}`);
            return true;
          }
          console.debug("Location is available but not authorized");
        }
        return false;
      }
      return true;
    } catch (error) {
      return false;
    }
  }

  /**
   * Gets the user location or throws an error of type PositionError with an error.message and an error.code.
   *  Codes 1,2,3 = Permission denied, Position unavailable for some reason, timeout.
   *
   * @param {boolean} [highAccuracy=false]
   * @param {number} [timeout=20000]
   * @returns {Observable<{ lat: number; lng: number }>}
   * @memberof PlatformHelperService
   */
  public async updateUserLocation(
    highAccuracy: boolean = false,
    timeout: number = 20000,
    forceUpdate = false
  ): Promise<{ lat: number; lng: number }> {
    if (typeof Geolocation !== "undefined") {
      let locationAvailable = true;
      if (this.isNative()) {
        locationAvailable = await this.isLocationServiceAvailable();
      }
      // If I'm not forcing an update and location services aren't available, then abort
      if (!forceUpdate && !locationAvailable) {
        const positionError: PositionError = new PositionError("Location services are disabled", 1);
        throw positionError;
      }
      try {
        const location = await Geolocation.getCurrentPosition({
          enableHighAccuracy: highAccuracy,
          timeout: timeout,
        });

        const item = {
          lat: location.coords.latitude,
          lng: location.coords.longitude,
        };

        return item;
      } catch (error) {
        if (isError(error)) {
          if (hasKey("code", error)) throw error;
          if (error.message.includes("CLError") && error.message.includes("error 1")) {
            error.code = 1;
          } else {
            error.code = 2;
          }
          throw error;
        }
      }
    }
    throw new PositionError("Geolocation is not enabled", 2);
  }

  public async takePhoto(): Promise<File> {
    const options = {
      quality: 75,
      allowEditing: false,
      resultType: CameraResultType.DataUrl,
      source: CameraSource.Camera,
    };
    if (typeof Camera !== "undefined") {
      const cameraPhoto = await Camera.getPhoto(options);
      if (cameraPhoto.dataUrl) {
        const file = this.dataUrlToFile(cameraPhoto.dataUrl, cameraPhoto.format);
        return file;
      } else throw new Error("Image not acessable");
    } else {
      throw new Error("Camera not accessable");
    }
  }

  public async choosePhoto(): Promise<File> {
    const options = {
      quality: 75,
      allowEditing: false,
      resultType: CameraResultType.DataUrl,
      source: CameraSource.Photos,
    };
    if (typeof Camera !== "undefined") {
      const cameraPhoto = await Camera.getPhoto(options);
      if (cameraPhoto.dataUrl) {
        const file = this.dataUrlToFile(cameraPhoto.dataUrl, cameraPhoto.format);
        return file;
      } else throw new Error("Image not acessable");
    } else {
      throw new Error("Camera not accessable");
    }
  }

  public addIsActiveListener(onState: "active" | "inactive", run: () => void) {
    if (App && this.isNative()) {
      App.addListener("appStateChange", (state: AppState) => {
        if (onState === "active" && state.isActive) {
          run();
        }
        if (onState === "inactive" && !state.isActive) {
          run();
        }
      });
    }
  }

  private dataUrlToFile(dataUrl: string, extension?: string): File {
    // convert base64/URLEncoded data component to raw binary data held in a string
    let byteString;
    if (dataUrl.split(",")[0].indexOf("base64") >= 0) byteString = atob(dataUrl.split(",")[1]);
    else byteString = unescape(dataUrl.split(",")[1]);

    // separate out the mime component
    const mimeString = dataUrl.split(",")[0].split(":")[1].split(";")[0];

    if (!extension) {
      const mimeParts = mimeString.split("/");
      if (mimeParts.length !== 2) throw new Error("Cannot find extension of file");
      extension = mimeParts[1];
    }

    // write the bytes of the string to a typed array
    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    const blob = new Blob([ia], { type: mimeString });
    if (!extension) extension = "test";
    const file = new File([blob], `photo.${extension}`, { type: mimeString });
    return file;
  }
}
