import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { City } from "../../graphql/generated/graphql.types";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { PopoverController } from "@ionic/angular";
import { AppleMapService } from "../../services/apple-map.service";
import { SortAndFilter, PlaceService } from "../../services/place.service";
import { RadioGroupChangeEventDetail, SelectChangeEventDetail } from "@ionic/core";
import { Place } from "../../models/place";

@Component({
  selector: "get-user-location",
  templateUrl: "./get-user-location.component.html",
  styleUrls: ["./get-user-location.component.scss"],
})
export class GetUserLocationComponent implements OnInit {
  @Input() cities: City[] = [];
  @Input() message?: string;
  @Input() showSubmit = true;
  @Input() previousSearchResult?: SortAndFilter["searchResult"];
  @Input() searchPlaces = false;
  @Input() scrollable = true;
  @Input() margin = 0;

  public form?: FormGroup;
  public searchResults: SortAndFilter["searchResult"][] = [];
  public searchStatus?: string;
  public time = 0;
  @Output() submitted: EventEmitter<SortAndFilter["searchResult"]> = new EventEmitter();
  @Output() changed: EventEmitter<SortAndFilter["searchResult"]> = new EventEmitter();
  @Output() valid: EventEmitter<boolean> = new EventEmitter();

  constructor(
    private popoverCtrl: PopoverController,
    private mapService: AppleMapService,
    private formBuilder: FormBuilder,
    private placeService: PlaceService
  ) {}

  ngOnInit() {
    let radioValue = "";
    if (this.previousSearchResult) {
      this.searchResults.push(this.previousSearchResult);
      radioValue = "0";
    }

    this.form = this.formBuilder.group({
      city: [this.cities[0]._id],
      search: [""],
      radio: [radioValue, Validators.required],
    });

    this.valid.emit(this.form.valid);
    if (this.form.controls.radio.value !== "city") this.form.controls.city.disable();
  }

  radioSelected($event: CustomEvent) {
    const details: RadioGroupChangeEventDetail = $event.detail;
    const result = this.getResult(details.value);
    if (result) {
      this.changed.emit(result);
      this.valid.emit(true);
    }
    if (this.form) {
      details.value !== "city" ? this.form.controls.city.disable() : this.form.controls.city.enable();
    }
  }

  cityChanged($event: CustomEvent) {
    const details: SelectChangeEventDetail = $event.detail;
    const result = this.getResult();
    if (result) {
      this.changed.emit(result);
    }
  }

  searchClicked() {
    if (!this.form) return;
    this.time = new Date().getTime();
    const searchTerm = this.form.controls.search.value;
    if (!searchTerm) return;

    this.valid.emit(false);
    if (this.form.value.radio !== "city") this.form.controls.radio.setValue("");

    this.searchStatus = "searching...";
    this.searchResults = [];

    if (this.searchPlaces) {
      this.placeService.getPlaces().subscribe((places) => {
        places = Place.filter(places, searchTerm);
        const placesSearchResult = places.map((place) => {
          const result: SortAndFilter["searchResult"] = {
            lat: place.lat,
            lng: place.lng,
            name: place.name,
            zoom: 0.2,
            id: place._id,
          };

          return result;
        });
        this.searchResults = this.searchResults.concat(placesSearchResult);
      });
    }

    this.mapService.loadSdk().subscribe((loaded) => {
      if (loaded) {
        this.mapService.lookupLocation(searchTerm).then((results) => {
          if (this.searchResults.length === 0 && results.length === 0) {
            this.searchStatus = `${searchTerm} not found`;
            if (this.previousSearchResult) this.searchResults.push(this.previousSearchResult);
          } else {
            this.searchResults = this.searchResults.concat(results);
            this.searchStatus = undefined;
          }
        });
      } else {
        this.searchStatus = undefined;
      }
    });
  }

  dismiss() {
    const result = this.getResult();
    if (result) {
      this.submitted.emit(result);
      this.popoverCtrl.dismiss(result);
    }
  }

  getResult(radio?: string): SortAndFilter["searchResult"] | undefined {
    if (!this.form) return;
    const radioValue: string = radio ? radio : this.form.controls.radio.value;
    let result: SortAndFilter["searchResult"];
    if (radioValue === "city") {
      const cityId = this.form.controls.city.value;
      if (this.cities) {
        const city: City | undefined = this.cities.find((searchedCity) => searchedCity._id === cityId);
        if (city) {
          result = {
            lat: city.lat,
            lng: city.lng,
            zoom: city.zoom,
            name: city.name,
            id: city._id,
          };
        }
      }
    } else {
      let index = Number(radioValue).valueOf();
      if (typeof index !== "number") return;
      index = index - this.time;
      if (this.searchResults.length > index) {
        result = this.searchResults[index];
      }
    }

    return result;
  }
}
