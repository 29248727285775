import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { NavController } from '@ionic/angular';
import { UserService } from '../services/user.service';
import { tap, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LoggedInGuard implements CanActivate {

  constructor(
    private userService: UserService,
    private navCtrl: NavController) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {

    return this.userService.isLoggedInAsync().pipe(
      tap((loggedIn: boolean) => {
        if (loggedIn) {
          this.navCtrl.navigateRoot('/account/detail');
          console.log('Rejected page because user is logged in');
        }
      }),
      map((loggedIn: boolean) => {
        return !loggedIn;
      })
    );

  }
}
