import {
  Location,
  LocationType,
  SanitizedUser,
  Comment,
  Image,
} from "../graphql/generated/graphql.types";

export type PlaceSortableProperties =
  | "averageRating"
  | "distance"
  | "commentCount"
  | "createdAt"
  | "lat"
  | "lng";

export type PlaceSortableDirections = "ascending" | "descending";

enum all {
  All = "ALL",
}

// TODO: Change this to extend LocationType
export type PlaceFilterType =
  | "FOOD"
  | "DRINKS"
  | "HIKING"
  | "ATTRACTION"
  | "SHOPPING"
  | "SKIING"
  | "KIDS"
  | "TRANSPORTATION"
  | "OTHER"
  | "ALL";

export class Place implements Location {
  _id: string;
  name: string;
  lat: number;
  lng: number;
  description: string;
  type: LocationType;
  commentCount: number;
  averageRating: number;
  owner: SanitizedUser;
  createdAt: number;
  updatedAt: number;
  comments: Comment[];
  images: Image[];
  views: number;
  navigateTos: number;
  author?: string;
  __typename: "Location" = "Location";

  constructor(location: Location) {
    this._id = location._id;
    this.name = location.name;
    this.lat = location.lat;
    this.lng = location.lng;
    this.type = location.type;
    this.description = location.description;
    this.owner = location.owner;
    this.createdAt = location.createdAt;
    this.updatedAt = location.updatedAt;
    this.comments = location.comments;
    this.commentCount = location.commentCount;
    this.averageRating = location.averageRating;
    this.images = location.images;
    this.views = location.views;
    this.navigateTos = location.navigateTos;
    if (location.author) this.author = location.author;
  }

  /**
   *Used to sort an array of Place
   *
   * @static
   * @param {Place[]} places - The array to be sorted
   * @param {PlaceSortableProperties} onProperty - The property you want to sort on
   * @param {PlaceSortableDirections} direction - The direction to sort
   * @param {{ lat: number; lng: number }} [from] - If sorting on distance, required. Distance to sort based on
   * @param {PlaceSortableProperties} [onSecondaryProperty] - If the values are the same, second property to sort on
   * @param {PlaceSortableDirections} [onSecondaryDirection] - If the values are the same, the sort
   * direction of the second property
   * @returns {Place[]}
   * @memberof Place
   */
  static sort(
    places: Place[],
    onProperty: PlaceSortableProperties,
    direction: PlaceSortableDirections,
    from?: { lat: number; lng: number },
    onSecondaryProperty?: PlaceSortableProperties,
    onSecondaryDirection?: PlaceSortableDirections
  ): Place[] {
    return places.sort((a, b) => {
      if (onProperty === "distance" || (a[onProperty] && b[onProperty])) {
        let difference = this.compareValues(a, b, onProperty, from);
        let localDirection = direction;
        if (
          difference === 0 &&
          onSecondaryDirection &&
          onSecondaryProperty &&
          (onSecondaryProperty === "distance" ||
            (a[onSecondaryProperty] && b[onSecondaryProperty]))
        ) {
          difference = this.compareValues(a, b, onSecondaryProperty, from);
          localDirection = onSecondaryDirection;
        }
        if (localDirection === "descending") {
          return difference * -1;
        }
        return difference;
      } else if (a[onProperty]) {
        return direction === "descending" ? -1 : 1;
      } else if (b[onProperty]) {
        return direction === "descending" ? 1 : -1;
      } else {
        return 0;
      }
    });
  }

  static filter(
    places: Place[],
    search?: string,
    type?: PlaceFilterType,
    distanceFrom?: { distance: number; lat: number; lng: number }
  ): Place[] {
    return places.filter((place) => {
      if (type && type !== "ALL" && place.type !== type) {
        return false;
      }
      if (
        search &&
        !(
          place.name.toLowerCase().indexOf(search.toLowerCase()) > -1 ||
          place.description.toLowerCase().indexOf(search.toLocaleLowerCase()) >
            -1 ||
          place.type.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) >
            -1
        )
      ) {
        return false;
      }
      if (
        distanceFrom &&
        this.getDistance(
          distanceFrom.lat,
          distanceFrom.lng,
          place.lat,
          place.lng
        ) > distanceFrom.distance
      ) {
        return false;
      }
      return true;
    });
  }

  // Returns a - b (ascending)
  static compareValues(
    a: Place,
    b: Place,
    onProperty: PlaceSortableProperties,
    from?: { lat: number; lng: number }
  ): number {
    if (onProperty === "distance") {
      const aDistance = Math.abs(
        this.getDistance(from!.lat, from!.lng, a.lat, a.lng)
      );
      const bDistance = Math.abs(
        this.getDistance(from!.lat, from!.lng, b.lat, b.lng)
      );
      return aDistance - bDistance;
    }

    if (a[onProperty] && b[onProperty]) {
      let aValue = 0;
      let bValue = 0;
      if (onProperty === "createdAt") {
        aValue = a.createdAt!;
        bValue = b.createdAt!;
      } else {
        aValue = a[onProperty]!;
        bValue = b[onProperty]!;
      }
      return aValue - bValue;
    } else {
      throw new Error("Passed an undefined property to comparing place values");
    }
  }

  static getDistance(lat1: number, lng1: number, lat2: number, lng2: number) {
    const toRadians = (angle: number): number => angle * (Math.PI / 180);
    const R = 3959; // Statute Miles
    const dLat = toRadians(lat2 - lat1); // deg2rad below
    const dLon = toRadians(lng2 - lng1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(toRadians(lat1)) *
        Math.cos(toRadians(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c; // Distance in statue miles
    return d;
  }

  static typeToIcon(type: LocationType) {
    switch (type) {
      case "FOOD":
        return "pizza";
      case "DRINKS":
        return "beer";
      case "HIKING":
        return "walk";
      case "ATTRACTION":
        return "rocket";
      case "SHOPPING":
        return "cart";
      case "SKIING":
        return "image";
      case "KIDS":
        return "happy";
      case "TRANSPORTATION":
        return "bus";
      case "OTHER":
        return "help";
    }
  }
}
