import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class JwtService {

    constructor() { }

    private base64Decode(string: string): string {
        let output = string.replace(/-/g, '+').replace(/_/g, '/');
        switch (output.length % 4) {
            case 0:
                {
                    break;
                }
            case 2:
                {
                    output += '==';
                    break;
                }
            case 3:
                {
                    output += '=';
                    break;
                }
            default:
                {
                    throw new Error('Illegal base64url string!');
                }
        }
        return decodeURIComponent(escape(window.atob(output)));
    }


    getTokenExpirationDate(token: string): Date {
        const decoded = this.decodeToken(token);

        if (decoded.hasOwnProperty('exp') && decoded.exp instanceof Date) {
            return decoded.exp;
        }

        throw new Error('No expiration date in the token');
    }

    isTokenExpired(token: string, offsetSeconds: number = 0): boolean {
        const expiration = this.getTokenExpirationDate(token);
        return !(expiration.valueOf() > (new Date().valueOf() + (offsetSeconds * 1000)));
    }

    private convertNumberToDate(date: number): Date {
        const expiration = new Date(0); // The 0 here is the key, which sets the date to the epoch
        expiration.setUTCSeconds(date);
        return expiration;
    }

    decodeToken(token: string): {[key: string]: any} {
        const parts = token.split('.');

        if (parts.length !== 3) {
            throw new Error('JWT does not have three parts');
        }

        const decoded = JSON.parse(this.base64Decode(parts[1]));

        if (decoded.hasOwnProperty('exp') && typeof decoded.exp === 'number') {
            decoded.exp = this.convertNumberToDate(decoded.exp);
        }

        if (decoded.hasOwnProperty('orig_iat') && typeof decoded.orig_iat === 'number') {
            decoded.orig_iat = this.convertNumberToDate(decoded.orig_iat);
        }

        return decoded;
    }
}
