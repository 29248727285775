import { Injectable } from "@angular/core";
import { HttpLink } from "apollo-angular/http";
import { Platform } from "@ionic/angular";
import { Config } from "../models/config";
import { User } from "../graphql/generated/graphql.types";
import { persistCache } from "apollo3-cache-persist";
import {
  ApolloClientOptions,
  InMemoryCache,
  defaultDataIdFromObject,
} from "@apollo/client/core";
import { StorageService } from "./storage.service";

@Injectable({
  providedIn: "root",
})
export class ApolloClientService {
  private uri = Config.GRAPHQL_BASE;
  private optionsPromise: Promise<ApolloClientOptions<any>>;
  private options?: ApolloClientOptions<any>;

  constructor(
    private httpLink: HttpLink,
    private platform: Platform,
    private storageService: StorageService
  ) {
    this.optionsPromise = this.createApollo();
  }

  private async createApollo(): Promise<ApolloClientOptions<any>> {
    if (Config.TESTING) {
      if (Config.TESTING_SIMULATOR && this.platform.is("android")) {
        this.uri = Config.ANDROID_LOCALHOST_GRAPHQL;
      } else if (
        !Config.TESTING_SIMULATOR &&
        (this.platform.is("ios") || this.platform.is("android"))
      ) {
        this.uri = "http://192.168.40.107:3000/graphql";
      } else this.uri = Config.LOCALHOST_GRAPHQL;
    }

    const cache = new InMemoryCache({
      dataIdFromObject: (object) => {
        switch (object.__typename) {
          case "user":
            const user: User = <User>object;
            return user.username;
          default:
            return defaultDataIdFromObject(object);
        }
      },
      addTypename: true,
      typePolicies: {
        Query: {
          fields: {
            location(existingData, { args, toReference }) {
              return (
                existingData ||
                toReference({ __typename: "Location", id: args?.id })
              );
            },
            locations: {
              keyArgs: false,
              merge(existing = [], incoming: any[]) {
                return [...incoming];
              },
            },
          },
        },
        Location: {
          fields: {
            comments: {
              merge(existing = [], incoming: any[]) {
                return [...incoming];
              },
            },
          },
        },
      },
    });

    await persistCache({
      cache,
      storage: {
        getItem: (key) => this.storageService.get(key),
        setItem: (key, data) => this.storageService.set(key, data),
        removeItem: (key) => this.storageService.remove(key),
      },
    });
    this.options = {
      link: this.httpLink.create({ uri: this.uri }),
      cache: cache,
      connectToDevTools: true,
    };

    return this.options;
  }

  public getOptionsPromise(): Promise<ApolloClientOptions<any>> {
    return this.optionsPromise;
  }

  public getOptions(): ApolloClientOptions<any> {
    if (!this.options)
      throw new Error("Options are not defined in Apollo Client");
    return this.options;
  }
}
