import { Injectable } from "@angular/core";
import { PlatformHelperService } from "./platform-helper.service";
import { Renderer2, RendererFactory2, Inject } from "@angular/core";
import { DOCUMENT } from "@angular/common";

import { Observer, Observable, BehaviorSubject, of } from "rxjs";
import { switchMap, tap, catchError } from "rxjs/operators";
import { SortAndFilter } from "./place.service";

@Injectable({
  providedIn: "root",
})
export class AppleMapService {
  private loadingSdk = false;
  private renderer: Renderer2;
  private sdkLoaded = false;
  private mapInjected: BehaviorSubject<boolean>;

  constructor(
    private rendererFactory: RendererFactory2,
    // private element: ElementRef,
    // private platform: Platform,
    private platformHelperService: PlatformHelperService,
    // private changeDetector: ChangeDetectorRef,
    @Inject(DOCUMENT) private document: HTMLDocument
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
    this.mapInjected = new BehaviorSubject<boolean>(false);
  }

  public loadSdk(): Observable<boolean> {
    if (this.sdkLoaded) return of(true);
    if (this.loadingSdk) return this.mapInjected;
    return this.platformHelperService.isOnline().pipe(
      switchMap((online) => {
        if (!online) throw new Error("Not online");
        if (this.sdkLoaded || this.loadingSdk) {
          if (this.sdkLoaded) return of(true);
          if (this.loadingSdk) return this.mapInjected;
        }
        this.loadingSdk = true;
        return this.injectSDK().pipe(
          tap((status) => {
            if (status) {
              this.sdkLoaded = true;
              this.loadingSdk = false;
              this.mapInjected.next(true);
            }
          }),
          catchError((error) => {
            this.loadingSdk = false;
            throw error;
          })
        );
      })
    );
  }

  private injectSDK(): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      const script = this.renderer.createElement("script");
      script.onload = () => {
        mapkit.init({
          authorizationCallback: function (done) {
            done(
              `eyJhbGciOiJFUzI1NiIsImtpZCI6IlRXQjNOQTg2V00iLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJMRlZHQ0YzNDRWIiwiaWF0IjoxNjY5NDExNjE1LCJleHAiOjE3MDA5NDc2MTV9.VoJlecNpDbK3bNT3ASzuj5G3wkmhb9BoMhNnoEEJYOP5rt0f8-90A73VItecCTMtFulfOcoTTICowQzqa0LUTA`
            );
            observer.next(true);
            observer.complete();
          },
        });
      };
      script.id = "appleMaps";
      script.src = "https://cdn.apple-mapkit.com/mk/5.x.x/mapkit.js";

      this.renderer.appendChild(this.document.body, script);
    });
  }

  public lookupLocation(query: string, near?: { lat: number; lng: number }): Promise<SortAndFilter["searchResult"][]> {
    if (!this.sdkLoaded) throw new Error("mapkit is not loaded while getting a geocoder location");
    const geoCoder = new mapkit.Geocoder();
    const options: any = {};

    return new Promise((resolve) => {
      if (near) {
        options.coordinate = new mapkit.Coordinate(near.lat, near.lng);
      }

      geoCoder.lookup(
        query,
        (error: Error | null, data: mapkit.GeocoderResponse) => {
          const results: SortAndFilter["searchResult"][] = [];
          data.results.forEach((response) => {
            const result: SortAndFilter["searchResult"] = {
              lat: response.coordinate.latitude,
              lng: response.coordinate.longitude,
              name: response.name || response.formattedAddress,
              zoom: 12,
              id: "gocoderLookup",
            };
            results.push(result);
          });

          resolve(results);
        },
        options
      );
    });
  }
}
