import { Injectable } from "@angular/core";
import { HttpHeaders } from "@angular/common/http";

import { timer, Observable, Observer, from, Subscription, of, lastValueFrom } from "rxjs";
import { catchError, map, tap, takeUntil } from "rxjs/operators";

import { User } from "../graphql/generated/graphql.types";
import { JwtService } from "./jwt.service";
import { SharedService } from "./shared.service";
import { PlatformHelperService } from "./platform-helper.service";
import {
  LoginGQL,
  LoginQueryVariables,
  RegisterGQL,
  CreateUserInput,
  LoginUserInput,
  ForgotPasswordGQL,
  ResetPasswordGQL,
  RefreshTokenGQL,
  UpdateUserGQL,
} from "../graphql/generated/graphql.types";
import { ApolloError } from "@apollo/client/core";
import { StorageService } from "./storage.service";

const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" }),
};

@Injectable({
  providedIn: "root",
})
export class UserService {
  private usernameObserver?: Observer<string>;
  private refreshTokenSubscription?: Subscription;
  private logInFromStoragePromise?: Promise<boolean>;

  public token?: string;
  public usernameObservable: Observable<string>; // Updates username on change, '' if logged out
  public user?: User;

  constructor(
    private jwtService: JwtService,
    private storageService: StorageService,
    private shared: SharedService,
    private platformHelperService: PlatformHelperService,
    private loginGql: LoginGQL,
    private registerGql: RegisterGQL,
    private forgotPasswordGql: ForgotPasswordGQL,
    private resetPasswordGql: ResetPasswordGQL,
    private refreshTokenGql: RefreshTokenGQL,
    private updateUserGql: UpdateUserGQL
  ) {
    this.usernameObservable = new Observable((observer: Observer<string>) => {
      this.usernameObserver = observer;
    });

    this.logInFromStorage().then((worked) => {
      console.log(`Logged in from storage: ${worked}`);
    });

    this.platformHelperService.addIsActiveListener("active", () => {
      // Will check if the token is expired and log-out if it is
      // I'm not sure if a timer will trigger that expired when an app becomes
      // active, so I'm restarting it.
      if (this.isLoggedIn() && this.token) this.logInWithToken(this.token);
    });
  }

  // Guarentees a user and a token are defined, also logs out if a token is expired
  isLoggedIn(): boolean {
    if (typeof this.token === "undefined" || typeof this.user === "undefined") {
      return false;
    }
    if (this.jwtService.isTokenExpired(this.token)) {
      this.logOut();
      return false;
    }
    return true;
  }

  isLoggedInAsync(): Observable<boolean> {
    if (typeof this.token === "undefined" || typeof this.user === "undefined") {
      if (typeof this.logInFromStoragePromise !== "undefined") {
        return from(this.logInFromStoragePromise);
      }
    }

    if (typeof this.token !== "undefined") {
      if (this.jwtService.isTokenExpired(this.token)) {
        this.logOut();
        return of(false);
      }
      return of(true);
    } else {
      return of(false);
    }
  }

  async logInFromStorage(): Promise<boolean> {
    if (typeof this.token === "undefined" || this.jwtService.isTokenExpired(this.token)) {
      this.logInFromStoragePromise = this.storageService.get("token")?.then((token) => {
        if (token != null && !this.jwtService.isTokenExpired(token)) {
          this.logInWithToken(token, true);
          return true;
        } else {
          return false;
        }
      });
      return this.logInFromStoragePromise ?? false;
    } else {
      throw new Error("Attempting to log in from storage while logged in");
    }
  }

  // User must be logged out
  // Attempts to get the user info from storage if no username/password provided or
  // or via an HTTP request if username / password not provided
  // Throws error or returns true
  logIn(loginUserInput: LoginUserInput): Observable<boolean> {
    // Throws error if user is logged in
    if (typeof this.token !== "undefined" && !this.jwtService.isTokenExpired(this.token)) {
      let message = "You are already logged in";
      if (typeof this.user !== "undefined" && typeof this.user.username !== "undefined") {
        message = message + ` as ${this.user.username}.`;
      }
      throw new Error(message);
    }

    // Attempt to log in from storage
    return from(
      this.logInFromStorage().then((result) => {
        // If the token was not in storage, try to login with credentials
        const loginData: LoginQueryVariables = {
          user: loginUserInput,
        };
        console.log("Debug: I made it here");
        if (!result) {
          return lastValueFrom(
            this.loginGql
              .fetch(loginData, { fetchPolicy: "no-cache" })
              //   return this.http
              //     .post(this.loginUrl, { username: username, password: password }, httpOptions)
              .pipe(
                map((response): boolean => {
                  // Register the token with this service and store the unsername and token
                  if (!response.errors) {
                    try {
                      this.logInWithToken(response.data.login.token);
                    } catch {
                      throw new Error("Server did not respond with proper token");
                    }

                    return true;
                  }
                  throw new Error("Invalid response from server");
                }),
                catchError((error: ApolloError) => {
                  throw this.shared.apolloErrorHandler(error, "Error with log-in");
                })
              )
          );
        } else {
          throw new Error("User is not currently in storage and no username/password provided");
        }
      })
    );
  }

  // TODO: Possibly check if page is okay & enforce guards if not
  logOut() {
    this.token = undefined;
    this.user = undefined;
    this.logInFromStoragePromise = undefined;

    // Tell anyone subscribed that user is logged out
    if (typeof this.usernameObserver !== "undefined") {
      this.usernameObserver.next("");
    }

    // If the token is being refreshed, unsubscribe
    if (typeof this.refreshTokenSubscription !== "undefined") {
      this.refreshTokenSubscription.unsubscribe();
    }

    this.storageService.remove("token");

    console.log("Logged out");
  }

  public register(createUserInput: CreateUserInput): Observable<undefined> {
    return this.registerGql.mutate({ createUserInput }, { fetchPolicy: "no-cache" }).pipe(
      map((userObject) => undefined),
      tap((updatedUser) => {
        this.logIn(createUserInput);
      }),
      catchError((error: ApolloError) => {
        throw this.shared.apolloErrorHandler(error, "Error registering user");
      })
    );
  }

  public changePassword(oldPassword: string, newPassword: string): Observable<boolean> {
    return this.updateUserGql
      .mutate(
        {
          fieldsToUpdate: {
            password: { oldPassword, newPassword },
          },
        },
        { fetchPolicy: "no-cache" }
      )
      .pipe(
        map((result) => true),
        catchError((error) => {
          throw this.shared.apolloErrorHandler(error, "Failed to change password");
        })
      );
  }

  public confirmResetPassword(username: string, password: string, code: string): Observable<boolean> {
    return this.resetPasswordGql
      .mutate(
        {
          password,
          code,
          username,
        },
        { fetchPolicy: "no-cache" }
      )
      .pipe(
        map((result) => true),
        catchError(this.shared.djangoErrorHandler<boolean>("Failed to reset password"))
      );
  }

  public forgotPassword(email: string): Observable<boolean> {
    return this.forgotPasswordGql.fetch({ email }, { fetchPolicy: "no-cache" }).pipe(
      // return this.http.post(this.resetPasswordUrl, { email: email }, httpOptions).pipe(
      map((result) => true),
      catchError((error) => {
        throw this.shared.apolloErrorHandler(error, "Failed to request password reset");
      })
    );
  }

  refreshTokenOnTimer(delaySeconds: number, intervalSeconds: number) {
    if (typeof this.refreshTokenSubscription !== "undefined") {
      this.refreshTokenSubscription.unsubscribe();
    }

    this.refreshTokenSubscription = timer(delaySeconds * 1000, intervalSeconds * 1000).subscribe((_) => {
      this.refreshToken()
        .pipe(takeUntil(timer(20000)))
        .subscribe({
          next: (result) => {
            if (!result) {
              console.log("Did not refresh token");
              this.logOut();
              return;
            }
            console.log("Token refreshed");
          },
          error: (error) => {
            console.log(error);
          },
        });
    });
  }

  // Logs in the user with the given token
  // Must have a token with a username
  // Sets up the User here
  // Stores the token for a refresh
  private logInWithToken(token: string, refreshImmediately = false) {
    this.token = token;

    const tokenValues = this.jwtService.decodeToken(token);

    if (typeof tokenValues.username === "string") {
      this.user = {
        username: tokenValues.username,
        email: tokenValues.email,
        _id: tokenValues.user_id,
        // TODO: Actually get users data
        permissions: [],
        createdAt: new Date().valueOf(),
        updatedAt: new Date().valueOf(),
        lastSeenAt: new Date().valueOf(),
        enabled: true,
      };

      if (typeof this.usernameObserver !== "undefined") {
        this.usernameObserver.next(this.user.username);
      }
    } else {
      // This should not happen with the API
      throw new Error("Invalid token");
    }

    let refreshDelta = 240;
    const timeBeforeExpForLastRefresh = 30;
    const refreshesDuringInterval = 2;
    if (tokenValues.exp instanceof Date) {
      refreshDelta =
        ((tokenValues.exp.getTime() - new Date().getTime()) / 1000 - timeBeforeExpForLastRefresh) /
        refreshesDuringInterval;
    }
    // console.log(refreshDelta);
    let startRefresh = refreshDelta;
    if (refreshImmediately) {
      startRefresh = 0;
    }
    this.refreshTokenOnTimer(startRefresh, refreshDelta);
    this.storageService.set("token", token);
  }

  refreshToken(): Observable<boolean> {
    // If the user is not logged in, return false
    if (!this.isLoggedIn()) {
      return of(false);
    }

    return this.refreshTokenGql.fetch(undefined, { fetchPolicy: "no-cache" }).pipe(
      // return this.http.post(this.refreshUrl, { token: this.token }, httpOptions).pipe(
      map((response: any): boolean => {
        if (response.data.refreshToken) {
          this.logInWithToken(response.data.refreshToken);
          return true;
        }
        throw new Error("Invalid response from server on token refresh");
      }),
      catchError((error: ApolloError) => {
        this.logOut();
        throw this.shared.apolloErrorHandler(error, "Error refreshing token");
      })
    );
  }
}
