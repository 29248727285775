import { Component, OnInit } from "@angular/core";
import { UserService } from "../../services/user.service";
import { Observable } from "rxjs";
import { AnnouncementService } from "../../services/announcement.service";
import { PlatformHelperService } from "../../services/platform-helper.service";

@Component({
  selector: "navigation-list",
  templateUrl: "./navigation-list.component.html",
  styleUrls: ["./navigation-list.component.scss"],
})
export class NavigationListComponent implements OnInit {
  public accountTabName = "Account";
  private accountTabNameObservable: Observable<string>;
  public newAnnouncements = 0;

  constructor(
    private userService: UserService,
    private announcementService: AnnouncementService,
    private platformService: PlatformHelperService
  ) {
    this.accountTabNameObservable = userService.usernameObservable;
    this.accountTabNameObservable.subscribe({
      next: (username) => {
        if (username !== "") {
          this.accountTabName = username;
        } else {
          this.accountTabName = "Account";
        }
      },
      error: (err) => console.log(err),
      complete: () =>
        console.log(
          "The username observable for the tab should never complete"
        ),
    });
    announcementService.newAnnouncements.subscribe((newAnnouncements) => {
      this.newAnnouncements = newAnnouncements;
    });
    this.announcementService.getAnnouncements("cache-and-network").subscribe();
  }

  isNative(): Boolean {
    return this.platformService.isNative();
  }

  ngOnInit() {}
}
