import gql from "graphql-tag";
import { Injectable } from "@angular/core";
import * as Apollo from "apollo-angular";
export type Maybe<T> = T | null;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Mongo ObjectId scalar type */
  MongoObjectId: string;
  /** Date custom scalar type */
  Date: number;
  Upload: any;
};

export type Announcement = {
  __typename?: "Announcement";
  text: Scalars["String"];
  title: Scalars["String"];
  _id: Scalars["MongoObjectId"];
  createdAt: Scalars["Date"];
  updatedAt: Scalars["Date"];
};

export type City = {
  __typename?: "City";
  name: Scalars["String"];
  lat: Scalars["Float"];
  lng: Scalars["Float"];
  zoom: Scalars["Int"];
  _id: Scalars["MongoObjectId"];
  createdAt: Scalars["Date"];
  updatedAt: Scalars["Date"];
};

export type Comment = {
  __typename?: "Comment";
  title?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  author?: Maybe<Scalars["String"]>;
  rating: Scalars["Int"];
  owner: SanitizedUser;
  _id: Scalars["MongoObjectId"];
  createdAt: Scalars["Date"];
  updatedAt: Scalars["Date"];
  locationId: Scalars["MongoObjectId"];
};

export type CreateCommentInput = {
  title?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  rating: Scalars["Int"];
  locationId: Scalars["MongoObjectId"];
};

export type CreateImageInput = {
  caption?: Maybe<Scalars["String"]>;
  locationId: Scalars["MongoObjectId"];
  image: Scalars["Upload"];
};

export type CreateLocationInput = {
  name: Scalars["String"];
  description: Scalars["String"];
  lat: Scalars["Float"];
  lng: Scalars["Float"];
  type: LocationType;
};

export type CreateUserInput = {
  username: Scalars["String"];
  email: Scalars["String"];
  password: Scalars["String"];
};

export type Image = {
  __typename?: "Image";
  urls: ImageUrls;
  owner: SanitizedUser;
  verified: Scalars["Boolean"];
  _id: Scalars["MongoObjectId"];
  createdAt: Scalars["Date"];
  updatedAt: Scalars["Date"];
  locationId: Scalars["MongoObjectId"];
  caption?: Maybe<Scalars["String"]>;
};

export type ImageUrls = {
  __typename?: "ImageUrls";
  original: Scalars["String"];
  thumb: Scalars["String"];
  large: Scalars["String"];
};

export type LastN = {
  __typename?: "LastN";
  locations: Array<Location>;
  users: Array<SanitizedUser>;
  comments: Array<Comment>;
  images: Array<Image>;
};

export type Location = {
  __typename?: "Location";
  name: Scalars["String"];
  description: Scalars["String"];
  lat: Scalars["Float"];
  lng: Scalars["Float"];
  type: LocationType;
  owner: SanitizedUser;
  comments: Array<Comment>;
  images: Array<Image>;
  author?: Maybe<Scalars["String"]>;
  _id: Scalars["MongoObjectId"];
  createdAt: Scalars["Date"];
  updatedAt: Scalars["Date"];
  commentCount: Scalars["Int"];
  averageRating: Scalars["Float"];
  views: Scalars["Int"];
  navigateTos: Scalars["Int"];
};

export enum LocationEvent {
  View = "VIEW",
  Navigateto = "NAVIGATETO"
}

export enum LocationType {
  Food = "FOOD",
  Drinks = "DRINKS",
  Hiking = "HIKING",
  Attraction = "ATTRACTION",
  Shopping = "SHOPPING",
  Skiing = "SKIING",
  Kids = "KIDS",
  Transportation = "TRANSPORTATION",
  Other = "OTHER"
}

export type LoginResult = {
  __typename?: "LoginResult";
  user: User;
  token: Scalars["String"];
};

export type LoginUserInput = {
  username?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  password: Scalars["String"];
};

export type Mutation = {
  __typename?: "Mutation";
  readAnnouncementJson?: Maybe<Scalars["Boolean"]>;
  readCityJson?: Maybe<Scalars["Boolean"]>;
  createComment: Comment;
  updateComment: Comment;
  readCommentJson?: Maybe<Scalars["Boolean"]>;
  deleteComment: Scalars["Boolean"];
  readImageJson?: Maybe<Scalars["Boolean"]>;
  createImage: Image;
  deleteImage: Scalars["Boolean"];
  reportImage: Scalars["Boolean"];
  createLocation: Location;
  updateLocation: Location;
  readLocationJson?: Maybe<Scalars["Boolean"]>;
  deleteLocation: Scalars["Boolean"];
  reportLocation: Scalars["Boolean"];
  addEventToLocation: Scalars["Boolean"];
  createUser: User;
  updateUser: User;
  addAdminPermission: User;
  removeAdminPermission: User;
  resetPassword: User;
  readUserJson?: Maybe<Scalars["Boolean"]>;
};

export type MutationCreateCommentArgs = {
  createCommentInput: CreateCommentInput;
};

export type MutationUpdateCommentArgs = {
  id: Scalars["MongoObjectId"];
  fieldsToUpdate: UpdateCommentInput;
};

export type MutationDeleteCommentArgs = {
  id: Scalars["MongoObjectId"];
};

export type MutationCreateImageArgs = {
  input: CreateImageInput;
};

export type MutationDeleteImageArgs = {
  id: Scalars["MongoObjectId"];
};

export type MutationReportImageArgs = {
  id: Scalars["MongoObjectId"];
  issue: Scalars["String"];
};

export type MutationCreateLocationArgs = {
  createLocationInput?: Maybe<CreateLocationInput>;
};

export type MutationUpdateLocationArgs = {
  id: Scalars["MongoObjectId"];
  fieldsToUpdate: UpdateLocationInput;
};

export type MutationDeleteLocationArgs = {
  id: Scalars["MongoObjectId"];
};

export type MutationReportLocationArgs = {
  id: Scalars["MongoObjectId"];
  issue: Scalars["String"];
};

export type MutationAddEventToLocationArgs = {
  id: Scalars["MongoObjectId"];
  event?: Maybe<LocationEvent>;
};

export type MutationCreateUserArgs = {
  createUserInput?: Maybe<CreateUserInput>;
};

export type MutationUpdateUserArgs = {
  fieldsToUpdate: UpdateUserInput;
  username?: Maybe<Scalars["String"]>;
};

export type MutationAddAdminPermissionArgs = {
  username: Scalars["String"];
};

export type MutationRemoveAdminPermissionArgs = {
  username: Scalars["String"];
};

export type MutationResetPasswordArgs = {
  username: Scalars["String"];
  code: Scalars["String"];
  password: Scalars["String"];
};

export type NearLatLngInput = {
  lat: Scalars["Float"];
  lng: Scalars["Float"];
  distance: Scalars["Float"];
};

export type Query = {
  __typename?: "Query";
  newest: LastN;
  announcements: Array<Announcement>;
  login: LoginResult;
  refreshToken: Scalars["String"];
  cities: Array<City>;
  comments: Array<Comment>;
  comment: Comment;
  images: Array<Image>;
  image: Image;
  locations: Array<Location>;
  location: Location;
  users: Array<User>;
  user: User;
  forgotPassword?: Maybe<Scalars["Boolean"]>;
};

export type QueryNewestArgs = {
  count: Scalars["Int"];
};

export type QueryLoginArgs = {
  user: LoginUserInput;
};

export type QueryCommentsArgs = {
  searchCommentsInput: SearchCommentsInput;
};

export type QueryCommentArgs = {
  id: Scalars["MongoObjectId"];
};

export type QueryImagesArgs = {
  input: SearchImagesInput;
};

export type QueryImageArgs = {
  id: Scalars["MongoObjectId"];
};

export type QueryLocationsArgs = {
  searchLocationsInput: SearchLocationsInput;
};

export type QueryLocationArgs = {
  id: Scalars["MongoObjectId"];
};

export type QueryUserArgs = {
  username?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
};

export type QueryForgotPasswordArgs = {
  email?: Maybe<Scalars["String"]>;
};

export type SanitizedUser = {
  __typename?: "SanitizedUser";
  username: Scalars["String"];
};

export type SearchCommentsInput = {
  locationId?: Maybe<Scalars["MongoObjectId"]>;
  ownerId?: Maybe<Scalars["MongoObjectId"]>;
  newest?: Maybe<Scalars["Int"]>;
};

export type SearchImagesInput = {
  locationId?: Maybe<Scalars["MongoObjectId"]>;
  ownerId?: Maybe<Scalars["MongoObjectId"]>;
  newest?: Maybe<Scalars["Int"]>;
};

export type SearchLocationsInput = {
  name?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  near?: Maybe<NearLatLngInput>;
  type?: Maybe<LocationType>;
  owner?: Maybe<Scalars["MongoObjectId"]>;
  newest?: Maybe<Scalars["Int"]>;
};

export type UpdateCommentInput = {
  title?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  rating?: Maybe<Scalars["Int"]>;
  ownerId?: Maybe<Scalars["MongoObjectId"]>;
  locationId?: Maybe<Scalars["MongoObjectId"]>;
};

export type UpdateLocationInput = {
  name?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  lat?: Maybe<Scalars["Float"]>;
  lng?: Maybe<Scalars["Float"]>;
  type?: Maybe<LocationType>;
  owner?: Maybe<Scalars["MongoObjectId"]>;
};

export type UpdatePasswordInput = {
  oldPassword: Scalars["String"];
  newPassword: Scalars["String"];
};

export type UpdateUserInput = {
  username?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  password?: Maybe<UpdatePasswordInput>;
  enabled?: Maybe<Scalars["Boolean"]>;
};

export type User = {
  __typename?: "User";
  username: Scalars["String"];
  email: Scalars["String"];
  permissions: Array<Scalars["String"]>;
  createdAt: Scalars["Date"];
  updatedAt: Scalars["Date"];
  lastSeenAt: Scalars["Date"];
  enabled: Scalars["Boolean"];
  djangoPk?: Maybe<Scalars["Int"]>;
  _id: Scalars["MongoObjectId"];
};
export type UserQueryVariables = {
  username: Scalars["String"];
};

export type UserQuery = { __typename?: "Query" } & {
  user: { __typename?: "User" } & Pick<
    User,
    | "username"
    | "email"
    | "_id"
    | "createdAt"
    | "updatedAt"
    | "permissions"
    | "enabled"
  >;
};

export type UsersQueryVariables = {};

export type UsersQuery = { __typename?: "Query" } & {
  users: Array<
    { __typename?: "User" } & Pick<
      User,
      | "username"
      | "email"
      | "_id"
      | "createdAt"
      | "updatedAt"
      | "permissions"
      | "enabled"
    >
  >;
};

export type LoginQueryVariables = {
  user: LoginUserInput;
};

export type LoginQuery = { __typename?: "Query" } & {
  login: { __typename?: "LoginResult" } & Pick<LoginResult, "token"> & {
      user: { __typename?: "User" } & Pick<
        User,
        | "username"
        | "email"
        | "_id"
        | "createdAt"
        | "updatedAt"
        | "permissions"
        | "enabled"
      >;
    };
};

export type RefreshTokenQueryVariables = {};

export type RefreshTokenQuery = { __typename?: "Query" } & Pick<
  Query,
  "refreshToken"
>;

export type UpdateUserMutationVariables = {
  fieldsToUpdate: UpdateUserInput;
};

export type UpdateUserMutation = { __typename?: "Mutation" } & {
  updateUser: { __typename?: "User" } & Pick<User, "updatedAt">;
};

export type RegisterMutationVariables = {
  createUserInput: CreateUserInput;
};

export type RegisterMutation = { __typename?: "Mutation" } & {
  createUser: { __typename?: "User" } & Pick<User, "username" | "_id">;
};

export type ResetPasswordMutationVariables = {
  username: Scalars["String"];
  code: Scalars["String"];
  password: Scalars["String"];
};

export type ResetPasswordMutation = { __typename?: "Mutation" } & {
  resetPassword: { __typename?: "User" } & Pick<User, "_id">;
};

export type ForgotPasswordQueryVariables = {
  email: Scalars["String"];
};

export type ForgotPasswordQuery = { __typename?: "Query" } & Pick<
  Query,
  "forgotPassword"
>;

export type DeleteLocationMutationVariables = {
  id: Scalars["MongoObjectId"];
};

export type DeleteLocationMutation = { __typename?: "Mutation" } & Pick<
  Mutation,
  "deleteLocation"
>;

export type LocationQueryVariables = {
  id: Scalars["MongoObjectId"];
};

export type LocationQuery = { __typename?: "Query" } & {
  location: { __typename?: "Location" } & LocationPartsFragment;
};

export type LocationsQueryVariables = {
  input: SearchLocationsInput;
};

export type LocationsQuery = { __typename?: "Query" } & {
  locations: Array<{ __typename?: "Location" } & LocationPartsFragment>;
};

export type CreateLocationMutationVariables = {
  input: CreateLocationInput;
};

export type CreateLocationMutation = { __typename?: "Mutation" } & {
  createLocation: { __typename?: "Location" } & LocationPartsFragment;
};

export type ReportLocationMutationVariables = {
  id: Scalars["MongoObjectId"];
  issue: Scalars["String"];
};

export type ReportLocationMutation = { __typename?: "Mutation" } & Pick<
  Mutation,
  "reportLocation"
>;

export type ReportImageMutationVariables = {
  id: Scalars["MongoObjectId"];
  issue: Scalars["String"];
};

export type ReportImageMutation = { __typename?: "Mutation" } & Pick<
  Mutation,
  "reportImage"
>;

export type CommentsQueryVariables = {
  searchCommentsInput: SearchCommentsInput;
};

export type CommentsQuery = { __typename?: "Query" } & {
  comments: Array<{ __typename?: "Comment" } & CommentPartsFragment>;
};

export type ImagesQueryVariables = {
  input: SearchImagesInput;
};

export type ImagesQuery = { __typename?: "Query" } & {
  images: Array<{ __typename?: "Image" } & ImagePartsFragment>;
};

export type ImageQueryVariables = {
  id: Scalars["MongoObjectId"];
};

export type ImageQuery = { __typename?: "Query" } & {
  image: { __typename?: "Image" } & ImagePartsFragment;
};

export type CreateCommentMutationVariables = {
  input: CreateCommentInput;
};

export type CreateCommentMutation = { __typename?: "Mutation" } & {
  createComment: { __typename?: "Comment" } & CommentPartsFragment;
};

export type CreateImageMutationVariables = {
  input: CreateImageInput;
};

export type CreateImageMutation = { __typename?: "Mutation" } & {
  createImage: { __typename?: "Image" } & ImagePartsFragment;
};

export type DeleteCommentMutationVariables = {
  id: Scalars["MongoObjectId"];
};

export type DeleteCommentMutation = { __typename?: "Mutation" } & Pick<
  Mutation,
  "deleteComment"
>;

export type DeleteImageMutationVariables = {
  id: Scalars["MongoObjectId"];
};

export type DeleteImageMutation = { __typename?: "Mutation" } & Pick<
  Mutation,
  "deleteImage"
>;

export type AddEventToLocationMutationVariables = {
  id: Scalars["MongoObjectId"];
  event?: Maybe<LocationEvent>;
};

export type AddEventToLocationMutation = { __typename?: "Mutation" } & Pick<
  Mutation,
  "addEventToLocation"
>;

export type AnnouncementsQueryVariables = {};

export type AnnouncementsQuery = { __typename?: "Query" } & {
  announcements: Array<
    { __typename?: "Announcement" } & Pick<
      Announcement,
      "title" | "text" | "_id" | "createdAt" | "updatedAt"
    >
  >;
};

export type CitiesQueryVariables = {};

export type CitiesQuery = { __typename?: "Query" } & {
  cities: Array<
    { __typename?: "City" } & Pick<
      City,
      "name" | "lat" | "lng" | "zoom" | "_id" | "createdAt" | "updatedAt"
    >
  >;
};

export type LocationPartsFragment = { __typename?: "Location" } & Pick<
  Location,
  | "name"
  | "description"
  | "lat"
  | "lng"
  | "type"
  | "author"
  | "commentCount"
  | "averageRating"
  | "views"
  | "navigateTos"
  | "_id"
  | "createdAt"
  | "updatedAt"
> & {
    owner: { __typename?: "SanitizedUser" } & Pick<SanitizedUser, "username">;
    comments: Array<{ __typename?: "Comment" } & CommentPartsFragment>;
    images: Array<{ __typename?: "Image" } & ImagePartsFragment>;
  };

export type CommentPartsFragment = { __typename?: "Comment" } & Pick<
  Comment,
  | "title"
  | "description"
  | "author"
  | "rating"
  | "_id"
  | "createdAt"
  | "updatedAt"
  | "locationId"
> & {
    owner: { __typename?: "SanitizedUser" } & Pick<SanitizedUser, "username">;
  };

export type ImagePartsFragment = { __typename?: "Image" } & Pick<
  Image,
  "verified" | "_id" | "createdAt" | "updatedAt" | "locationId" | "caption"
> & {
    urls: { __typename?: "ImageUrls" } & Pick<
      ImageUrls,
      "original" | "large" | "thumb"
    >;
    owner: { __typename?: "SanitizedUser" } & Pick<SanitizedUser, "username">;
  };
export const CommentPartsFragmentDoc = gql`
  fragment CommentParts on Comment {
    title
    description
    author
    rating
    owner {
      username
    }
    _id
    createdAt
    updatedAt
    locationId
  }
`;
export const ImagePartsFragmentDoc = gql`
  fragment ImageParts on Image {
    urls {
      original
      large
      thumb
    }
    owner {
      username
    }
    verified
    _id
    createdAt
    updatedAt
    locationId
    caption
  }
`;
export const LocationPartsFragmentDoc = gql`
  fragment LocationParts on Location {
    name
    description
    lat
    lng
    type
    owner {
      username
    }
    comments {
      ...CommentParts
    }
    images {
      ...ImageParts
    }
    author
    commentCount
    averageRating
    views
    navigateTos
    _id
    createdAt
    updatedAt
  }
  ${CommentPartsFragmentDoc}
  ${ImagePartsFragmentDoc}
`;
export const UserDocument = gql`
  query user($username: String!) {
    user(username: $username) {
      username
      email
      _id
      createdAt
      updatedAt
      permissions
      enabled
    }
  }
`;

@Injectable({
  providedIn: "root"
})
export class UserGQL extends Apollo.Query<UserQuery, UserQueryVariables> {
  document = UserDocument;
}
export const UsersDocument = gql`
  query users {
    users {
      username
      email
      _id
      createdAt
      updatedAt
      permissions
      enabled
    }
  }
`;

@Injectable({
  providedIn: "root"
})
export class UsersGQL extends Apollo.Query<UsersQuery, UsersQueryVariables> {
  document = UsersDocument;
}
export const LoginDocument = gql`
  query login($user: LoginUserInput!) {
    login(user: $user) {
      token
      user {
        username
        email
        _id
        createdAt
        updatedAt
        permissions
        enabled
      }
    }
  }
`;

@Injectable({
  providedIn: "root"
})
export class LoginGQL extends Apollo.Query<LoginQuery, LoginQueryVariables> {
  document = LoginDocument;
}
export const RefreshTokenDocument = gql`
  query refreshToken {
    refreshToken
  }
`;

@Injectable({
  providedIn: "root"
})
export class RefreshTokenGQL extends Apollo.Query<
  RefreshTokenQuery,
  RefreshTokenQueryVariables
> {
  document = RefreshTokenDocument;
}
export const UpdateUserDocument = gql`
  mutation updateUser($fieldsToUpdate: UpdateUserInput!) {
    updateUser(fieldsToUpdate: $fieldsToUpdate) {
      updatedAt
    }
  }
`;

@Injectable({
  providedIn: "root"
})
export class UpdateUserGQL extends Apollo.Mutation<
  UpdateUserMutation,
  UpdateUserMutationVariables
> {
  document = UpdateUserDocument;
}
export const RegisterDocument = gql`
  mutation register($createUserInput: CreateUserInput!) {
    createUser(createUserInput: $createUserInput) {
      username
      _id
    }
  }
`;

@Injectable({
  providedIn: "root"
})
export class RegisterGQL extends Apollo.Mutation<
  RegisterMutation,
  RegisterMutationVariables
> {
  document = RegisterDocument;
}
export const ResetPasswordDocument = gql`
  mutation resetPassword(
    $username: String!
    $code: String!
    $password: String!
  ) {
    resetPassword(username: $username, code: $code, password: $password) {
      _id
    }
  }
`;

@Injectable({
  providedIn: "root"
})
export class ResetPasswordGQL extends Apollo.Mutation<
  ResetPasswordMutation,
  ResetPasswordMutationVariables
> {
  document = ResetPasswordDocument;
}
export const ForgotPasswordDocument = gql`
  query forgotPassword($email: String!) {
    forgotPassword(email: $email)
  }
`;

@Injectable({
  providedIn: "root"
})
export class ForgotPasswordGQL extends Apollo.Query<
  ForgotPasswordQuery,
  ForgotPasswordQueryVariables
> {
  document = ForgotPasswordDocument;
}
export const DeleteLocationDocument = gql`
  mutation deleteLocation($id: MongoObjectId!) {
    deleteLocation(id: $id)
  }
`;

@Injectable({
  providedIn: "root"
})
export class DeleteLocationGQL extends Apollo.Mutation<
  DeleteLocationMutation,
  DeleteLocationMutationVariables
> {
  document = DeleteLocationDocument;
}
export const LocationDocument = gql`
  query location($id: MongoObjectId!) {
    location(id: $id) {
      ...LocationParts
    }
  }
  ${LocationPartsFragmentDoc}
`;

@Injectable({
  providedIn: "root"
})
export class LocationGQL extends Apollo.Query<
  LocationQuery,
  LocationQueryVariables
> {
  document = LocationDocument;
}
export const LocationsDocument = gql`
  query locations($input: SearchLocationsInput!) {
    locations(searchLocationsInput: $input) {
      ...LocationParts
    }
  }
  ${LocationPartsFragmentDoc}
`;

@Injectable({
  providedIn: "root"
})
export class LocationsGQL extends Apollo.Query<
  LocationsQuery,
  LocationsQueryVariables
> {
  document = LocationsDocument;
}
export const CreateLocationDocument = gql`
  mutation createLocation($input: CreateLocationInput!) {
    createLocation(createLocationInput: $input) {
      ...LocationParts
    }
  }
  ${LocationPartsFragmentDoc}
`;

@Injectable({
  providedIn: "root"
})
export class CreateLocationGQL extends Apollo.Mutation<
  CreateLocationMutation,
  CreateLocationMutationVariables
> {
  document = CreateLocationDocument;
}
export const ReportLocationDocument = gql`
  mutation reportLocation($id: MongoObjectId!, $issue: String!) {
    reportLocation(id: $id, issue: $issue)
  }
`;

@Injectable({
  providedIn: "root"
})
export class ReportLocationGQL extends Apollo.Mutation<
  ReportLocationMutation,
  ReportLocationMutationVariables
> {
  document = ReportLocationDocument;
}
export const ReportImageDocument = gql`
  mutation reportImage($id: MongoObjectId!, $issue: String!) {
    reportImage(id: $id, issue: $issue)
  }
`;

@Injectable({
  providedIn: "root"
})
export class ReportImageGQL extends Apollo.Mutation<
  ReportImageMutation,
  ReportImageMutationVariables
> {
  document = ReportImageDocument;
}
export const CommentsDocument = gql`
  query comments($searchCommentsInput: SearchCommentsInput!) {
    comments(searchCommentsInput: $searchCommentsInput) {
      ...CommentParts
    }
  }
  ${CommentPartsFragmentDoc}
`;

@Injectable({
  providedIn: "root"
})
export class CommentsGQL extends Apollo.Query<
  CommentsQuery,
  CommentsQueryVariables
> {
  document = CommentsDocument;
}
export const ImagesDocument = gql`
  query images($input: SearchImagesInput!) {
    images(input: $input) {
      ...ImageParts
    }
  }
  ${ImagePartsFragmentDoc}
`;

@Injectable({
  providedIn: "root"
})
export class ImagesGQL extends Apollo.Query<ImagesQuery, ImagesQueryVariables> {
  document = ImagesDocument;
}
export const ImageDocument = gql`
  query image($id: MongoObjectId!) {
    image(id: $id) {
      ...ImageParts
    }
  }
  ${ImagePartsFragmentDoc}
`;

@Injectable({
  providedIn: "root"
})
export class ImageGQL extends Apollo.Query<ImageQuery, ImageQueryVariables> {
  document = ImageDocument;
}
export const CreateCommentDocument = gql`
  mutation CreateComment($input: CreateCommentInput!) {
    createComment(createCommentInput: $input) {
      ...CommentParts
    }
  }
  ${CommentPartsFragmentDoc}
`;

@Injectable({
  providedIn: "root"
})
export class CreateCommentGQL extends Apollo.Mutation<
  CreateCommentMutation,
  CreateCommentMutationVariables
> {
  document = CreateCommentDocument;
}
export const CreateImageDocument = gql`
  mutation CreateImage($input: CreateImageInput!) {
    createImage(input: $input) {
      ...ImageParts
    }
  }
  ${ImagePartsFragmentDoc}
`;

@Injectable({
  providedIn: "root"
})
export class CreateImageGQL extends Apollo.Mutation<
  CreateImageMutation,
  CreateImageMutationVariables
> {
  document = CreateImageDocument;
}
export const DeleteCommentDocument = gql`
  mutation DeleteComment($id: MongoObjectId!) {
    deleteComment(id: $id)
  }
`;

@Injectable({
  providedIn: "root"
})
export class DeleteCommentGQL extends Apollo.Mutation<
  DeleteCommentMutation,
  DeleteCommentMutationVariables
> {
  document = DeleteCommentDocument;
}
export const DeleteImageDocument = gql`
  mutation DeleteImage($id: MongoObjectId!) {
    deleteImage(id: $id)
  }
`;

@Injectable({
  providedIn: "root"
})
export class DeleteImageGQL extends Apollo.Mutation<
  DeleteImageMutation,
  DeleteImageMutationVariables
> {
  document = DeleteImageDocument;
}
export const AddEventToLocationDocument = gql`
  mutation AddEventToLocation($id: MongoObjectId!, $event: LocationEvent) {
    addEventToLocation(id: $id, event: $event)
  }
`;

@Injectable({
  providedIn: "root"
})
export class AddEventToLocationGQL extends Apollo.Mutation<
  AddEventToLocationMutation,
  AddEventToLocationMutationVariables
> {
  document = AddEventToLocationDocument;
}
export const AnnouncementsDocument = gql`
  query announcements {
    announcements {
      title
      text
      _id
      createdAt
      updatedAt
    }
  }
`;

@Injectable({
  providedIn: "root"
})
export class AnnouncementsGQL extends Apollo.Query<
  AnnouncementsQuery,
  AnnouncementsQueryVariables
> {
  document = AnnouncementsDocument;
}
export const CitiesDocument = gql`
  query cities {
    cities {
      name
      lat
      lng
      zoom
      _id
      createdAt
      updatedAt
    }
  }
`;

@Injectable({
  providedIn: "root"
})
export class CitiesGQL extends Apollo.Query<CitiesQuery, CitiesQueryVariables> {
  document = CitiesDocument;
}
