import { Observable, BehaviorSubject, from } from "rxjs";
import { Injectable } from "@angular/core";
import { map, catchError, tap, switchMap, take } from "rxjs/operators";
import { PlatformHelperService } from "./platform-helper.service";
import {
  AnnouncementsGQL,
  Announcement,
} from "../graphql/generated/graphql.types";
import { WatchQueryFetchPolicy } from "@apollo/client/core";

@Injectable({
  providedIn: "root",
})
export class AnnouncementService {
  private announcements?: Announcement[];
  public newAnnouncements = new BehaviorSubject<number>(0);

  constructor(
    private platformHelperService: PlatformHelperService,
    private announcementsGql: AnnouncementsGQL
  ) {
    this.platformHelperService.addIsActiveListener("active", () => {
      this.getAnnouncements("network-only")
        .pipe(take(1))
        .subscribe({
          next: () => console.log("Got announcments when active"),
          error: (error) => console.log(error.message),
        });
    });
  }

  public getAnnouncements(
    fetchPolicy: WatchQueryFetchPolicy = "cache-first"
  ): Observable<Announcement[]> {
    return this.announcementsGql
      .watch(undefined, { fetchPolicy })
      .valueChanges.pipe(
        map((result) => {
          if (!result.data) return [];
          return this.sortAnnouncementsByDate([...result.data.announcements]);
        }),
        tap((newAnnouncements) => {
          let newAnnouncementCount = 0;
          newAnnouncements.forEach((newAnnouncement) => {
            if (
              this.announcements &&
              !this.announcements.some(
                (announcement) => announcement.text === newAnnouncement.text
              )
            ) {
              newAnnouncementCount++;
            }
          });
          this.newAnnouncements.next(newAnnouncementCount);
          this.announcements = newAnnouncements;
        }),
        catchError((error) => {
          console.log(`Announcement error: ${error.message}`);
          return from([]);
        })
      );
  }

  public resetNewAnnouncements() {
    this.newAnnouncements.next(0);
  }

  public sortAnnouncementsByDate(
    announcements: Announcement[]
  ): Announcement[] {
    return announcements.sort((a, b) => {
      if (
        typeof a.createdAt !== "undefined" &&
        typeof b.createdAt !== "undefined"
      ) {
        return b.createdAt - a.createdAt;
      } else if (typeof a.createdAt !== "undefined") {
        return -1;
      } else if (typeof b.createdAt !== "undefined") {
        return 1;
      } else {
        return 0;
      }
    });
  }

  private downloadAnnouncements(
    fetchPolicy: WatchQueryFetchPolicy = "cache-first"
  ): Observable<Announcement[]> {
    return this.announcementsGql
      .watch(undefined, { fetchPolicy })
      .valueChanges.pipe(
        map((result) => {
          return this.sortAnnouncementsByDate(result.data.announcements);
        })
      );
  }
}
