import { NgModule, APP_INITIALIZER } from "@angular/core";
import { APOLLO_OPTIONS } from "apollo-angular";
import { ApolloClientService } from "./services/apollo-client.service";

export function resolveApolloClientOptions(apolloClientService: ApolloClientService) {
  return () => apolloClientService.getOptionsPromise();
}

export function apolloClientServiceFactory(apolloClientService: ApolloClientService) {
  return apolloClientService.getOptions();
}

@NgModule({
  exports: [],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: resolveApolloClientOptions,
      deps: [ApolloClientService],
      multi: true,
    },
    ApolloClientService,
    {
      provide: APOLLO_OPTIONS,
      useFactory: apolloClientServiceFactory,
      deps: [ApolloClientService, APP_INITIALIZER],
    },
  ],
})
export class GraphQLModule {}
