import { NgModule } from "@angular/core";
import {
  Routes,
  RouterModule,
  PreloadAllModules,
  NoPreloading,
} from "@angular/router";
import { NotLoggedInGuard } from "./auth/not-logged-in.guard";
import { LoggedInGuard } from "./auth/logged-in.guard";

const routes: Routes = [
  {
    path: "",
    redirectTo: "home",
    pathMatch: "full",
  },
  {
    path: "home",
    children: [
      {
        path: "",
        loadChildren: () =>
          import("./pages/home/home.module").then((m) => m.HomePageModule),
      },
      {
        path: "place/:id",
        children: [
          {
            path: "",
            loadChildren: () =>
              import("./pages/place-detail/place-detail.module").then(
                (m) => m.PlaceDetailPageModule
              ),
          },
        ],
      },
      {
        path: "image/:id",
        children: [
          {
            path: "",
            loadChildren: () =>
              import("./pages/image-detail/image-detail.module").then(
                (m) => m.ImageDetailPageModule
              ),
          },
        ],
      },
      {
        path: "privacy",
        children: [
          {
            path: "",
            loadChildren: () =>
              import("./pages/privacy/privacy.module").then(
                (m) => m.PrivacyPageModule
              ),
          },
        ],
      },
    ],
  },
  {
    path: "map",
    children: [
      {
        path: "",
        loadChildren: () =>
          import("./pages/map/map.module").then((m) => m.MapPageModule),
      },
      {
        path: "place/:id",
        children: [
          {
            path: "",
            loadChildren: () =>
              import("./pages/place-detail/place-detail.module").then(
                (m) => m.PlaceDetailPageModule
              ),
          },
        ],
      },
      {
        path: "image/:id",
        children: [
          {
            path: "",
            loadChildren: () =>
              import("./pages/image-detail/image-detail.module").then(
                (m) => m.ImageDetailPageModule
              ),
          },
        ],
      },
    ],
  },
  {
    path: "announcements",
    children: [
      {
        path: "",
        loadChildren: () =>
          import("./pages/about/about.module").then((m) => m.AboutPageModule),
      },
    ],
  },
  {
    path: "account",
    children: [
      {
        path: "change-password",
        canActivate: [NotLoggedInGuard],
        children: [
          {
            path: "",
            loadChildren: () =>
              import("./pages/change-password/change-password.module").then(
                (m) => m.ChangePasswordPageModule
              ),
          },
        ],
      },
      {
        path: "reset/:uid/:token",
        canActivate: [LoggedInGuard],
        children: [
          {
            path: "",
            loadChildren: () =>
              import(
                "./pages/password-reset-confirm/password-reset-confirm.module"
              ).then((m) => m.PasswordResetConfirmPageModule),
          },
        ],
      },
      {
        path: "reset",
        canActivate: [LoggedInGuard],
        children: [
          {
            path: "",
            loadChildren: () =>
              import("./pages/password-reset/password-reset.module").then(
                (m) => m.PasswordResetPageModule
              ),
          },
        ],
      },
      {
        path: "detail",
        canActivate: [NotLoggedInGuard],
        children: [
          {
            path: "",
            loadChildren: () =>
              import("./pages/account-detail/account-detail.module").then(
                (m) => m.AccountDetailPageModule
              ),
          },
        ],
      },
      {
        path: "register",
        canActivate: [LoggedInGuard],
        children: [
          {
            path: "",
            loadChildren: () =>
              import("./pages/register/register.module").then(
                (m) => m.RegisterPageModule
              ),
          },
        ],
      },
      {
        path: "login",
        canActivate: [LoggedInGuard],
        children: [
          {
            path: "",
            loadChildren: () =>
              import("./pages/login/login.module").then(
                (m) => m.LoginPageModule
              ),
          },
        ],
      },
      {
        canActivate: [LoggedInGuard],
        path: "",
        loadChildren: () =>
          import("./pages/login/login.module").then((m) => m.LoginPageModule),
      },
    ],
  },
  {
    path: "privacy",
    loadChildren: () =>
      import("./pages/privacy/privacy.module").then((m) => m.PrivacyPageModule),
  },
  {
    path: "help",
    loadChildren: () =>
      import("./pages/help/help.module").then((m) => m.HelpPageModule),
  },
];
@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: NoPreloading })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
