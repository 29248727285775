import { Injectable } from "@angular/core";

import { Storage } from "@capacitor/storage";

@Injectable({
  providedIn: "root",
})
export class StorageService {
  private _storage: Storage | null = null;

  // Create and expose methods that users of this service can
  // call, for example:
  public set(key: string, value: any) {
    return Storage.set({ key, value });
  }

  public get(key: string) {
    return Storage.get({ key }).then((result) => result.value);
  }

  public remove(key: string) {
    return Storage.remove({ key });
  }
}
