import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouteReuseStrategy } from "@angular/router";

import { IonicModule, IonicRouteStrategy } from "@ionic/angular";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";

// Not default Ionic, possibly move to services that use http
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClientXsrfModule } from "@angular/common/http";
import { TokenInterceptor } from "./services/interceptors/token.interceptor";
import { ServiceWorkerModule } from "@angular/service-worker";
import { environment } from "../environments/environment";
import { Diagnostic } from "@ionic-native/diagnostic/ngx";
import { GetUserLocationComponent } from "./components/get-user-location/get-user-location.component";
import { ReactiveFormsModule } from "@angular/forms";
import { GetUserLocationModule } from "./components/get-user-location/get-user-location.module";
import { NavigationListComponent } from "./components/navigation-list/navigation-list.component";
import { GraphQLModule } from "./graphql.module";
import { ApolloModule } from "apollo-angular";

@NgModule({
  declarations: [AppComponent, NavigationListComponent],
  entryComponents: [GetUserLocationComponent],
  imports: [
    GetUserLocationModule,
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    HttpClientXsrfModule.withOptions({
      cookieName: "csrftoken",
      headerName: "X-CSRFToken",
    }),
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: environment.production,
    }),
    ReactiveFormsModule,
    GraphQLModule,
    ApolloModule,
  ],
  exports: [NavigationListComponent],
  providers: [
    {
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    Diagnostic,
    GetUserLocationComponent,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
