import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { switchMap, tap } from "rxjs/operators";
import { ApolloError } from "@apollo/client/core";

@Injectable({
  providedIn: "root",
})
export class SharedService {
  constructor() {}

  djangoErrorHandler<T>(fallbackMessage: string) {
    return (error: any): Observable<T> => {
      if (error.hasOwnProperty("error") && !("target" in error.error)) {
        const properties: string[] = Object.getOwnPropertyNames(error.error);
        if (properties.length > 0) {
          let errorMessage = "";

          for (const property in error.error) {
            if (
              Array.isArray(error.error[property]) &&
              error.error[property].length > 0
            ) {
              for (const message of error.error[property]) {
                errorMessage = `${errorMessage}${message} `;
              }
            } else {
              errorMessage = `${errorMessage}${error.error[property]} `;
            }
          }
          throw new Error(errorMessage.trim());
        }
      }
      if (
        error.hasOwnProperty("message") &&
        typeof error.message === "string" &&
        !error.message.includes("wolfpackplaces.xyz")
      ) {
        throw new Error(error.message);
      } else {
        throw new Error(fallbackMessage);
      }
    };
  }

  apolloErrorHandler(error: ApolloError, fallbackMessage: string): Error {
    if (error.graphQLErrors.length > 0) {
      let errorMessage = "";

      error.graphQLErrors.forEach((graphQlError) => {
        errorMessage = `${errorMessage}${graphQlError.message} `;
      });

      return new Error(errorMessage.trim());
    } else if (error.networkError) {
      return new Error(`Network error, check connection`);
    }
    return new Error(fallbackMessage);
  }

  // Saves you from having to write the second observable twice
  runFirstObservableIf<T, U>(
    isTrue: boolean,
    firstObservable: Observable<T>,
    secondObservable: () => Observable<U>
  ): Observable<U> {
    if (isTrue) {
      return firstObservable.pipe(
        switchMap(() => {
          return secondObservable();
        })
      );
    }
    return secondObservable();
  }
}
