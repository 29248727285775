import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '../services/user.service';
import { NavController } from '@ionic/angular';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class NotLoggedInGuard implements CanActivate {
  constructor(private userService: UserService, private navCtrl: NavController) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.userService.isLoggedInAsync().pipe(
      tap((loggedIn: boolean) => {
        if (!loggedIn) {
          this.navCtrl.navigateRoot('/account/login');
          console.log('Rejected page because user is not logged in');
        }
      })
    );
  }
}
