export class Config {
  public static PRODUCTION = true; // For Analytics, uncomment index.html fathom
  public static TESTING = false; // For the local database
  public static TESTING_SIMULATOR = false; //  To use the simulator URLs below
  public static GRAPHQL_BASE = "https://www.wolfpackplaces.xyz/graphql";
  public static LOCALHOST_GRAPHQL = "http://localhost:3000/graphql";
  public static IOS_TpmESTING_LAN_GRAPHQL =
    "http://192.168.40.107:3000/graphql";
  public static ANDROID_LOCALHOST_GRAPHQL = "http://10.0.2.2:3000/graphql";
}
