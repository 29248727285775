import { Component } from "@angular/core";
import { SplashScreen } from "@capacitor/splash-screen";
import { StatusBar } from "@capacitor/status-bar";

import { Router, NavigationEnd } from "@angular/router";
import { PlatformHelperService } from "./services/platform-helper.service";
import { Config } from "./models/config";

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
})
export class AppComponent {
  production = Config.PRODUCTION;

  constructor(router: Router, platformHelperService: PlatformHelperService) {
    if (SplashScreen && StatusBar) {
      SplashScreen.hide().catch((err: any) => {
        console.warn(err);
      });
    }

    if (Config.PRODUCTION) {
      router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          let url = location.href;
          (<any>window)._paq.push(["setReferrerUrl", url]);
          console.log("url", url);
          (<any>window)._paq.push(["setCustomUrl", url]);
          // _paq.push(['setDocumentTitle', 'My New Title']);

          // remove all previously assigned custom variables, requires Matomo (formerly Piwik) 3.0.2
          (<any>window)._paq.push(["deleteCustomVariables", "page"]);
          (<any>window)._paq.push(["trackPageView"]);
        }
      });

      platformHelperService.addIsActiveListener("active", () => {
        let url = location.href;
        (<any>window)._paq.push(["setReferrerUrl", url]);
        console.log("url", url);
        (<any>window)._paq.push(["setCustomUrl", url]);
        // _paq.push(['setDocumentTitle', 'My New Title']);

        // remove all previously assigned custom variables, requires Matomo (formerly Piwik) 3.0.2
        (<any>window)._paq.push(["deleteCustomVariables", "page"]);
        (<any>window)._paq.push(["trackPageView"]);
      });
    }
  }
}
